.modalContent {
  padding: 20px 24px;
  background: #333333;
  color: var(--app-text-color);
  border-radius: 5px;

  .titleContainer {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .formContent {
    text-align: center;

    .submitBtn {
      border: none;
      height: 50px;
      min-width: 250px;
      background: linear-gradient(90deg, #9873ff 0%, #3948d1 100%);
      border-radius: 10px;
    }
  }

  .alertMsgContainer {
    // display: flex;
    // align-items: center;
    // justify-content: center;

    // > span {
    //   margin-left: 5px;
    // }
  }
}

